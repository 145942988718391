import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/core';
import { toastController } from '@ionic/vue';
import type { AxiosError } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { refreshCircleOutline, alertCircleOutline } from 'ionicons/icons';

// import { RequirementsEnum, ResponseErrorEnum } from '@/enums';
import {
  showToast,
  useUserFlow,
  useTokenHelper,
  useRequirementsHelper,
  useErrors,
} from '@/helpers';
import { DEFAULT_LOCALE, useI18n, SUPPORT_LOCALES } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  ResponseVersionModel,
} from '@/types';

// Helpers
const { t } = useI18n();

// Icons
const icons = {
  alert: alertCircleOutline,
  refresh: refreshCircleOutline,
};

// Axios instance creation
const axiosInstance = axios.create({
  headers: {
    common: {
      'Accept-Language': SUPPORT_LOCALES.includes(
        window.navigator.language.substring(0, 2).toLowerCase()
      )
        ? window.navigator.language.substring(0, 2).toLowerCase()
        : DEFAULT_LOCALE,
    },
  },
});

// Request retry behavior
// const onReqFulfilled = async (config: any) => {};
// const onReqRejected = async (e: AxiosError) => {};

// const retry = 0;
if (import.meta.env.VITE_AXIOS_RETRY_ENABLED === 'true') {
  axiosRetry(axiosInstance, {
    retries: Number(import.meta.env.VITE_AXIOS_RETRY_COUNT),
    retryDelay: (retryCount) =>
      retryCount === 1
        ? import.meta.env.VITE_AXIOS_RETRY_AFTER_FIRST_ERROR_DELAY
        : Number(import.meta.env.VITE_AXIOS_RETRY_AFTER_FIRST_ERROR_DELAY) / 2,
    retryCondition: (error) =>
      error.request.responseType !== 'blob' &&
      error.config?.url !== undefined &&
      !error.config.url.startsWith('/oauth') &&
      error?.response?.status === 502,
    onRetry: async (retryCount, error) => {
      console.log(
        '🚀 ~ axiosRetry --> repeat the query, attempt: ',
        retryCount,
        error
      );

      if (retryCount === 1) {
        await showToast(t('retryResponse'), false, false, icons.refresh);
      }

      if (retryCount === Number(import.meta.env.VITE_AXIOS_RETRY_COUNT)) {
        toastController.getTop().then((toast) => {
          return toast ? toastController.dismiss() : null;
        });

        if (error.response?.status !== 200) {
          console.error('Error in axiosRetry: ', error);
          return await showToast(t('errorResponse'), false, false, icons.alert);
        }
      }
    },
  });
}

// Request interceptor
axiosInstance.interceptors.request.use(
  // Function to be executed before the request is sent
  async function onFulfilled(config: any): Promise<any> {
    const appStore = useAppStore();
    const tokenHelper = useTokenHelper();
    const controller = new AbortController();
    const dataAuth = await tokenHelper.getNewToken(false);

    // Add the base URL to the request
    config.baseURL = `${dataAuth.url}${import.meta.env.VITE_APP_URL_API}`;

    // If the user is authorized, add the token to the request headers
    if (dataAuth.token !== null) {
      config.headers.Authorization = `${import.meta.env.VITE_APP_AUTHORIZATION_KEY} ${dataAuth.token}`;
    }

    config.headers.BrowserPlatforms = getPlatforms();
    config.headers.CapacitorPlatform = Capacitor.getPlatform();
    config.headers.IsNativePlatform = Capacitor.isNativePlatform()
      ? 'True'
      : 'False';
    config.headers.ApiVersion = import.meta.env.VITE_APP_API;

    // If the user's token is null and token is not expired - canceling the request
    if (dataAuth.token === null && !tokenHelper.isCodeExpired()) {
      console.error('Error in request interceptor - 1: token is null');
      controller.abort();
    }

    // If the user authorized and the token is expired - log out the user and redirect to the login page
    if (appStore.isAuth && tokenHelper.isCodeExpired()) {
      console.error('Error in request interceptor - 2: token is expired');
      controller.abort();
      await Promise.all([
        useUserFlow().logOut(),
        router.replace({ name: ROUTES_NAME.LOGIN }),
      ]);
    }

    return { ...config, signal: controller.signal };
  },

  // Function to be executed if the request fails
  async function onRejected(error: AxiosError<any>): Promise<any> {
    useErrors().handleError(true, error);
    return undefined;
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  async function onFulfilled(response: any): Promise<any> {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return Object.assign(response.data, {
      statusCode: response.status,
    });
  },

  async function onRejected(error: AxiosError<any>): Promise<any> {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const eType = useErrors().handleError(true, error); // ResponseErrorTypeEnum | string | ResponseErrorTypeEnum.UnknownError;
    return Object.assign(error.response?.data, {
      statusCode: error.response?.status,
      errorType: eType,
    });
  }
);

// Helper function to get the application version to be exported outside of this file
export async function getAppVersion(): Promise<string> {
  const appStore = useAppStore();
  let urlApp = '';

  const url = `${appStore.url}${import.meta.env.VITE_APP_URL_API}/tools/appVersion`;
  await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      ApiVersion: import.meta.env.VITE_APP_API,
    },
    body: JSON.stringify({
      version: appStore.appVersion,
      //?: build: appStore.appBuild,
    }),
  })
    .then(async (response: Response) => {
      if (response.ok) {
        await response.json().then((responseToken: ResponseVersionModel) => {
          urlApp = responseToken.data;
        });
      } else {
        console.log(response.status);
      }
    })
    .catch((e: any) => {
      console.log(e);
    });

  return urlApp;
}

export default axiosInstance;
