import { useErrors } from './useErrorsHelper';

interface IListeners {
  useListeners: () => void;
}

let instance: IListeners | null = null;

export function useListeners(): IListeners {
  if (instance) {
    return instance;
  }

  window.addEventListener('error', (event) => {
    const { handleError } = useErrors();

    if (
      event.target instanceof HTMLScriptElement &&
      event.message.includes('MIME')
    ) {
      console.error('Detected MIME type error'); //! DEBUG
      handleError(true, undefined, 'Detected MIME type error');
      // clearCache();
    }
  });

  window.addEventListener('unhandledrejection', (event) => {
    const { handleError } = useErrors();

    if (event.reason.message.includes('MIME')) {
      console.error('Detected MIME type error'); //! DEBUG
      handleError(true, undefined, 'Detected MIME type error');
      // clearCache();
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function clearCache() {
    window.location.href =
      window.location.href.split('?')[0] + '?reload=' + new Date().getTime();
  }

  instance = {
    useListeners,
  };

  return instance;
}
