import { SplashScreen } from '@capacitor/splash-screen';
import { alertController } from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  CompanyMenuEnum,
  PagesBackgroundEnum,
  PostsModeEnum,
  RequirementsEnum,
} from '@/enums';
import {
  isNativeMobile,
  useWebSockets,
  useTheme,
  HomePageHelper,
  componentNetworkChangeModal,
  updateApp,
  changeFavicon,
  ColorGenerator,
  useRequirementsHelper,
  useAiAssistant,
  useImages,
  useMenu,
  useNotifications,
} from '@/helpers';
import { useI18n, loadLocaleMessages } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import type { AppState } from '@/store';
import {
  useAppStore,
  useMessengerStore,
  useNetworkStore,
  useGroupsStore,
  useUserStore,
  useNotificationsStore,
  usePostStore,
  useMenuStore,
  resetAllStores,
  useAiAssistantStore,
} from '@/store';
import type { AuthUser, NetworkModel, UserCurrentModel } from '@/types';

export type IUseUserFlow = {
  /**
   * @description The current user of the app.
   * @see src/plugins/sentry.ts
   */
  localUser: UserCurrentModel | null;
  /**
   * @description General setup function for the app
   * @summary Setup network, user, profile settings, locales, check required conditions, to app + init WebSockets and/or Push Notifications.
   * Accepts newWebSocket and newPush as optional parameters for _checkRequiredConditions => _toApp.

   * @param newWebSocket boolean
   * @description If newWebSocket is false, then it will NOT re-initialize WebSockets connection.
   * @default true
   * @see src/helpers/useWebSockets.ts

   * @param newPush boolean
   * @description If newPush is false, then it will NOT re-initialize Push Notifications. It will hoverer if isPushInitialized is false.
   * @default true
   * @see src/helpers/useNotificationsHelper.ts

   * @returns Promise<void>
   */
  setupApp: (newWebSocket?: boolean, newPush?: boolean) => Promise<void>;
  setNetwork: (network: NetworkModel, data: AuthUser | null) => Promise<void>;

  /**
   * Changes the network for the user.
   * Displays a modal to select a network and sets it.
   * @param {NetworkModel[] | undefined} userNetworks - The networks available to the user.
   * @param {AuthUser | null} data - The authenticated user data.
   * @returns {Promise<void>} A promise that resolves when the network is changed.
   */
  changeNetwork: (
    userNetworks: NetworkModel[] | undefined,
    data: AuthUser | null
  ) => Promise<void>;

  /**
   * Logs out the user from the application.
   * Stops WebSockets, unregisters push notifications, and resets all stores.
   * @returns {Promise<void>} A promise that resolves when the user is logged out.
   */
  logOut: () => Promise<void>;

  /**
   * Clears the custom menu refresh timer.
   */
  clearCustomMenuTimer: () => void;

  /**
   * Sets a timer to refresh the custom menu at regular intervals.
   */
  setCustomMenuTimer: () => void;
};

export const useUserFlow = (): IUseUserFlow => {
  //* Variables
  let localUser: UserCurrentModel | null = null;

  //* Stores
  const appStore = useAppStore();
  const networkStore = useNetworkStore();
  const userStore = useUserStore();
  const notificationStore = useNotificationsStore();
  const groupsStore = useGroupsStore();
  const menuStore = useMenuStore();
  const postStore = usePostStore();
  const messengerStore = useMessengerStore();
  const aiAssistantStore = useAiAssistantStore();

  //* Helpers
  const themeHelper = useTheme();

  //* Private
  const _setupNetwork = async (): Promise<boolean> => {
    if (networkStore.network.id.length === 0) {
      await networkStore.networkForUser(appStore.clientSecret); //!: Potential BUG - 1
    }

    if (networkStore.data.length === 0) {
      await networkStore.networksByUser(); //!: Potential BUG - 2
    }

    const settings = await networkStore.getSettings(appStore.clientSecret); //?: Potential BUG - 3 - Not really since it is called only if clientSecret is truthy

    if (!settings) {
      return false;
    }

    const color = themeHelper.getAppColor(settings?.headBackgroundColor);
    await themeHelper.setTheme(color);
    changeFavicon();
    return true;
  };

  const _setupUser = async (): Promise<UserCurrentModel | null> => {
    localUser = await userStore.currentUser();

    if (!localUser) {
      return null;
    }

    appStore.$patch({
      userId: localUser.id,
    });

    return localUser;
  };

  const _showErrorAlert = async (errors: string[]): Promise<void> => {
    const alert = await alertController.create({
      message: errors.join(' '),
    });
    await alert.present();
  };

  const _handleSetupAppError = async (error: any): Promise<void> => {
    console.error(error);
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    appStore.$patch({
      loading: false,
    });

    await router?.replace(ROUTES_NAME.LOGIN);
    const networkErrors = networkStore.getErrors('default');
    const userErrors = userStore.getErrors('default');
    const errors =
      userErrors.length && !networkErrors.length ? userErrors : networkErrors;
    Sentry ? Sentry.setUser(null) : null;
    _showErrorAlert(errors);
    return;
  };

  const _setupProfileSettings = (localUser: UserCurrentModel): void => {
    appStore.$patch((state: AppState) => {
      state.loading = true;

      state.theme = Object.hasOwn(localUser, 'themeStyle')
        ? localUser.themeStyle
        : state.theme;

      state.pagesBackground = Object.hasOwn(localUser, 'pagesBackground')
        ? localUser.pagesBackground
          ? PagesBackgroundEnum.CustomImage
          : PagesBackgroundEnum.Default
        : state.pagesBackground;

      state.locale = Object.hasOwn(localUser, 'language')
        ? localUser.language
        : state.locale;

      state.sendKey = Object.hasOwn(localUser, 'sendMode')
        ? localUser.sendMode
        : state.sendKey;

      state.localNotifications = Object.hasOwn(localUser, 'sound')
        ? localUser.sound
        : state.localNotifications;

      state.canPostOnBehalfMe = Object.hasOwn(localUser, 'canPostOnBehalfMe')
        ? localUser.canPostOnBehalfMe
        : state.canPostOnBehalfMe;
    });

    appStore.toggleTheme(appStore.theme);
  };

  const _setupLocales = async (): Promise<void> => {
    const currentNetworkId: ComputedRef<string> = computed(
      () => networkStore.getNetwork.id
    );
    const isBKGNetwork: ComputedRef<boolean> = computed(
      () =>
        useMenu().companiesList.value[CompanyMenuEnum.BKG] ===
        currentNetworkId.value
    );
    const isAWONetwork: ComputedRef<boolean> = computed(
      () =>
        useMenu().companiesList.value[CompanyMenuEnum.AWO] ===
        currentNetworkId.value
    );
    const companyResources = await appStore.getCompanyResources();
    await loadLocaleMessages(
      useI18n(),
      appStore.locale,
      companyResources,
      isBKGNetwork.value,
      isAWONetwork.value
    );
  };

  const _toApp = async (newWebSocket = true, newPush = true): Promise<void> => {
    const route = router.currentRoute.value;
    if (!localUser) {
      if (isNativeMobile) {
        await SplashScreen.hide();
      }

      await router?.push(ROUTES_NAME.LOGIN);
      return;
    }

    if (newWebSocket) {
      await useWebSockets().initWebSockets(appStore.getWebSocketModel);
    }

    if (newPush || !useNotifications().isPushInitialized) {
      await useNotifications(true).initPushNotifications();
    }

    userStore.$reset();
    userStore.$patch({
      current: localUser,
    });

    appStore.$patch({
      feedType: localUser.defaultFeedType,
    });

    /* NOTE:
      If initialization was caused by mobile local/push notification handling
      we need to ignore following routing logic since final route is now dictated by push notification type/id
    */
    if (!appStore.fromMobilePush) {
      await HomePageHelper.setHomePage();
      if (
        route?.name === ROUTES_NAME.LOGIN ||
        route?.name === ROUTES_NAME.HOME
      ) {
        await router?.replace(appStore.homePage);
      } else {
        if (HomePageHelper.getHomePage()?.name === ROUTES_NAME.FEED) {
          await router?.replace({ name: ROUTES_NAME.FEED });
          postStore.$patch((state) => {
            state.postsMode = PostsModeEnum.Feed;
            state.postsGroupId = null;
            state.postsUserId = null;
          });
        } else {
          await router?.replace(appStore.homePage);
        }
      }
    }

    if (isNativeMobile) {
      await SplashScreen.hide();
      await updateApp();
    }

    await messengerStore.getChains();
    for (let i = 0; i < 10 && messengerStore.loadMoreUrl; i++) {
      await messengerStore.loadMore(messengerStore.loadMoreUrl);
    }
  };

  const _checkRequiredConditions = async (
    newWebSocket?: boolean,
    newPush?: boolean
  ): Promise<void> => {
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    const result = await useRequirementsHelper().check(RequirementsEnum.All);
    if (result) {
      appStore.$patch({
        loading: false,
      });
      await _toApp(newWebSocket, newPush);
    }
  };

  //* Public
  const setupApp = async (
    newWebSocket?: boolean,
    newPush?: boolean
  ): Promise<void> => {
    clearCustomMenuTimer();

    resetAllStores(false);

    ColorGenerator.generateRandomShades();

    try {
      const _result = await _setupNetwork();
      if (!_result) {
        throw new Error('Network error');
      }

      localUser = await _setupUser();
      if (!localUser) {
        throw new Error('Current user error');
      }
    } catch (error) {
      await _handleSetupAppError(error);
      return;
    }

    //NOTE: Sentry
    if (Sentry) {
      Sentry.setUser({
        email: localUser?.email,
        id: localUser?.id.toString(),
        username: localUser?.fullName,
      });
    }

    //NOTE: Profile settings
    _setupProfileSettings(localUser);

    //NOTE: Locales
    await _setupLocales();

    //NOTE: Check Policy + Password + ForcePosts
    await _checkRequiredConditions(newWebSocket, newPush);

    //NOTE: Notifications
    await notificationStore.notifications();

    //NOTE: CanPostGroups
    await groupsStore.canPostGroups();

    //NOTE: Get custom menu and set refresh by timer
    await menuStore.getMenu(); //!: Potential BUG - 4
    setCustomMenuTimer();
  };

  const setNetwork = async (
    network: NetworkModel,
    userData: AuthUser | null
  ): Promise<void> => {
    networkStore.$patch({
      settingNetwork: true,
    });

    const appCurrent = {
      url: appStore.url,
      companyRowId: appStore.companyRowId,
      loading: false,
    };

    if (network.id !== '') {
      appStore.$patch((state) => {
        state.url = `https://${network.hostWithSubHost}`;
        state.companyRowId = network.id;
        state.loading = true;
        state.webSocket = network.webSocket;
        state.isWaitingForCompleteLogin = true;
      });

      let isAuth = false;
      if (userData && !appStore.isAuth) {
        isAuth = await appStore.homeCode(userData);
      }

      if (!userData && appStore.isAuth) {
        isAuth = true;
      }

      if (isAuth && (await appStore.token(true))) {
        networkStore.$patch((state) => {
          state.network = network;
          state.settingNetwork = false;
        });

        await setupApp(); //!: Potential BUG - 1
      } else {
        const errors = appStore.getErrors('default');
        appStore.$patch(appCurrent);
        _showErrorAlert(errors);
      }
    }
    networkStore.$patch({
      settingNetwork: false,
    });
  };

  const changeNetwork = async (
    userNetworks: NetworkModel[] | undefined,
    data: AuthUser | null
  ): Promise<void> => {
    if (userNetworks !== undefined) {
      if (userNetworks.length === 1) {
        await setNetwork(userNetworks[0], data);
      } else {
        const result = await componentNetworkChangeModal(false);
        if (result.data !== undefined) {
          await setNetwork(result.data, data);
        }
      }
    }
  };

  const logOut = async (): Promise<void> => {
    await useWebSockets().stopWebSockets();
    await useNotifications().resetBadgeCount();

    const currentAssistant = aiAssistantStore.assistant;
    if (currentAssistant) {
      await useAiAssistant().deleteAssistant(currentAssistant);
    }

    if (isNativeMobile) {
      await useNotifications().cancelPushNotifications();
    }

    clearCustomMenuTimer();

    resetAllStores(true);

    changeFavicon();
    await themeHelper.clearTheme();
    useImages().clearImages();
  };

  //* Normal Functions to be accessible from everywhere
  function setCustomMenuTimer() {
    const customMenuTimerId = setInterval(
      async () => {
        await menuStore.getMenu(); //!: Potential BUG - 4
      },
      15 * 60 * 1000
    );
    menuStore.$patch({
      timer: customMenuTimerId,
    });
  }

  function clearCustomMenuTimer() {
    const customMenuTimerId = menuStore.timer;
    if (customMenuTimerId) {
      clearInterval(customMenuTimerId);
    }
  }

  return {
    localUser,
    setupApp,
    setNetwork,
    changeNetwork,
    logOut,
    clearCustomMenuTimer,
    setCustomMenuTimer,
  };
};
