<template>
  <template v-if="viewType === DocsViewTypeEnum.Table && isMDWidth">
    <app-row
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      class="table-row"
      :class="{ active: isActive }"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <app-col :size="browser === DocBrowserTypeEnum.ByGroup ? '7' : '5'">
        <ion-item lines="none">
          <icons-provider
            v-if="status === null || status !== FileStatusEnum.Loading"
            slot="start"
            class="file-icon"
            :name="nameForIconsProvider(documentType, name)"
            :icon-props="{
              width: '30',
              height: '30',
            }"
          />
          <icons-provider
            v-else
            slot="start"
            :icon-props="{ width: '30', height: '30' }"
            class="spinner"
            name="circleAnim"
          />
          <div class="text">
            <ion-label :title="name">{{ name }}</ion-label>
          </div>
          <ion-buttons
            v-if="status && status === FileStatusEnum.Loading"
            slot="end"
          >
            <ion-button @click.stop="stopDownload()">
              <ion-icon slot="icon-only" :icon="icons.close" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </app-col>
      <app-col v-if="browser !== DocBrowserTypeEnum.ByGroup" size="3">
        <div class="text">
          <ion-label :title="group" v-html="group" />
        </div>
      </app-col>
      <app-col size="2">
        <div class="text">
          <ion-label v-if="editor" :title="editor" v-html="editor" />
          <ion-label v-else-if="author" :title="author" v-html="author" />
        </div>
      </app-col>
      <app-col size="2">
        <div class="text">
          <ion-label
            v-if="formattedEditedAt"
            :title="formattedEditedAt"
            v-html="formattedEditedAt"
          />
          <ion-label
            v-else-if="formattedCreatedAt"
            :title="formattedCreatedAt"
            v-html="formattedCreatedAt"
          />
        </div>
      </app-col>
    </app-row>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Table && !isMDWidth">
    <ion-item
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      mode="md"
      lines="none"
      button
      class="mobile"
      :class="{ active: isActive }"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <icons-provider
        v-if="status === FileStatusEnum.Loading"
        slot="start"
        :icon-props="{ width: '40', height: '40' }"
        name="circleAnim"
      />
      <icons-provider
        v-else-if="!isImage"
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, name)"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <div v-if="isImage" class="file-image">
        <media-image :name="name" :image="file.image" cover center />
      </div>
      <ion-label>
        {{ name }}
        <p v-if="formattedEditedAt">
          {{ formattedEditedAt + ' / ' + group }}
        </p>
        <p v-else-if="formattedCreatedAt">
          {{ formattedCreatedAt + ' / ' + group }}
        </p>
      </ion-label>
      <ion-buttons
        v-if="status && status === FileStatusEnum.Loading"
        slot="end"
      >
        <ion-button @click.stop="stopDownload()">
          <ion-icon slot="icon-only" :icon="icons.close" />
        </ion-button>
      </ion-buttons>
    </ion-item>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Grid">
    <div
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      class="grid-item"
      :class="{ active: isActive }"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
      @click="openFile()"
    >
      <div v-if="isImage" class="item-image">
        <media-image :name="name" :image="file.image" center />
      </div>
      <div v-if="!isImage" class="item-icon">
        <icons-provider
          class="file-icon"
          :name="nameForIconsProvider(documentType, name)"
          :icon-props="{
            width: '70',
            height: '70',
          }"
        />
      </div>

      <div class="item-label">
        <ion-label>{{ name }}</ion-label>
      </div>
    </div>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Item">
    <!-- Папки временно не показываем -->
    <ion-item
      v-if="
        documentType !== DocumentTypeEnum.ExternalLink &&
        documentType !== DocumentTypeEnum.Folder
      "
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <icons-provider
        v-if="status === null || status !== FileStatusEnum.Loading"
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, name)"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <icons-provider
        v-else
        slot="start"
        :icon-props="{ width: '40', height: '40' }"
        name="circleAnim"
      />
      <ion-label>
        {{ name }}
        <p>{{ size }}</p>
      </ion-label>
      <ion-buttons
        v-if="status && status === FileStatusEnum.Loading"
        slot="end"
      >
        <ion-button @click.stop="stopDownload()">
          <ion-icon slot="icon-only" :icon="icons.close" />
        </ion-button>
      </ion-buttons>
    </ion-item>

    <!-- Folder item in widgets -->
    <ion-item
      v-if="documentType === DocumentTypeEnum.Folder"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, name)"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label
        >{{ name }}
        <p>
          {{ author + ', ' + formatDateHelper(createdAt, 'year')
          }}<span v-if="group">{{ ', ' + group }}</span>
        </p></ion-label
      >
    </ion-item>

    <!-- External link item in widgets -->
    <ion-item
      v-if="documentType === DocumentTypeEnum.ExternalLink"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, name)"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label>
        {{ name }}
        <p>{{ linkUrl }}</p>
      </ion-label>
    </ion-item>
  </template>
</template>

<script lang="ts" setup>
import { IonIcon, IonItem, IonLabel, IonButtons, IonButton } from '@ionic/vue';
import { format } from 'date-fns';
import { closeOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { AppRow, AppCol, MediaImage, IconsProvider } from '@/components';
import { LongPress } from '@/directives';
import {
  AppIconsEnum,
  DocBrowserTypeEnum,
  DocsFilterTypeEnum,
  DocsViewTypeEnum,
  DocumentTypeEnum,
  FileStatusEnum,
} from '@/enums';
import { formatDateHelper, isNativeMobile, filesHybrid } from '@/helpers';
import { useAppStore, useDocStore } from '@/store';
import type { DocEntity, FileModel } from '@/types';

// Props
const props = defineProps({
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
  documentType: {
    type: String as PropType<DocumentTypeEnum>,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  mimeType: {
    type: String || null,
    default: () => null,
  },
  name: {
    type: String,
    required: true,
  },
  author: {
    type: String,
    required: true,
  },
  editor: {
    type: String,
    required: true,
  },
  group: {
    type: String,
    required: true,
  },
  createdAt: {
    type: String,
    required: true,
  },
  editedAt: {
    type: String,
    required: true,
  },
  size: {
    type: String || null,
    default: () => null,
  },
  file: {
    type: (Object as PropType<FileModel>) || null,
    default: () => null,
  },
  icon: {
    type: String,
    required: true,
  },
  status: {
    type: (String as PropType<FileStatusEnum>) || null,
    default: () => null,
  },
  linkUrl: {
    type: String || null,
    default: () => null,
  },
  fileMenuIsDisabled: {
    type: Boolean,
    default: () => false,
  },
});

// Helpers
const vLongPress = LongPress;

// Icons
const icons = {
  close: closeOutline,
};

// Store
const appStore = useAppStore();
const docStore = useDocStore();
const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);

// Variables
const filterBy: ComputedRef<string | null> = computed(
  () => docStore.filterBy ?? null
);
const filterType: ComputedRef<DocsFilterTypeEnum | null> = computed(
  () => docStore.filterType ?? null
);
const browser: ComputedRef<DocBrowserTypeEnum | null> = computed(
  () => docStore.browserType
);
const selectedDocs: ComputedRef<DocEntity[]> = computed(
  () => docStore.selectedDocs
);
const isActive: ComputedRef<boolean> = computed(() =>
  selectedDocs.value.some(
    (n) => n.data.id === props.id && props.documentType === n.documentType
  )
);
const formattedCreatedAt: ComputedRef<string> = computed(() =>
  formatDateHelper(props.createdAt, 'short')
);

const formattedEditedAt: ComputedRef<string> = computed(() =>
  formatDateHelper(props.editedAt, 'short')
);

const isFiltered: ComputedRef<boolean> = computed(() => {
  const filterByAuthor =
    filterBy.value === props.author &&
    filterType.value === DocsFilterTypeEnum.Author;

  const filterByGroup =
    filterBy.value === props.group &&
    filterType.value === DocsFilterTypeEnum.Group;

  const filterByDate =
    filterBy.value ===
      format(new Date(props.editedAt || props.createdAt), 'dd.MM.yyyy') &&
    filterType.value === DocsFilterTypeEnum.Date;

  const filterByType =
    filterBy.value === props.documentType &&
    filterType.value === DocsFilterTypeEnum.Type;

  return filterByAuthor || filterByGroup || filterByDate || filterByType;
});

const isImage = computed(() => {
  return (
    props.file?.mimeType.startsWith('image') &&
    !filesHybrid.unsupportedImageFormats.includes(props.file.type)
  );
});

// Actions
const openFile = async () => {
  emit('onItemClick');
};

const openMenu = async (ev: Event) => {
  if (!props.fileMenuIsDisabled) {
    emit('onMenuOpen', ev);
  }
};

const nameForIconsProvider = (documentType: DocumentTypeEnum, name: string) => {
  switch (documentType) {
    case DocumentTypeEnum.Wiki:
      return AppIconsEnum.Wiki;
    case DocumentTypeEnum.Folder:
      return AppIconsEnum.FolderYellowClose;
    case DocumentTypeEnum.ExternalLink:
      return AppIconsEnum.Symlink;
    default:
      return name.split('.').pop() ?? '';
  }
};

const stopDownload = async () => {
  emit('onDownloadStop');
};

// Emits
const emit = defineEmits(['onItemClick', 'onMenuOpen', 'onDownloadStop']);
</script>
<style scoped lang="scss">
.table-row {
  min-height: 48px;
  border-bottom: 1px solid var(--ion-color-custom-element-lighter);
  align-items: center;
}
.table-row:last-child {
  border-bottom: none;
}
.table-row:hover {
  cursor: pointer;
}
.table-row.active {
  background-color: var(--ion-color-custom-element-lighter);
}
.table-row .app-col {
  padding-top: 0;
  padding-bottom: 0;
}
.table-row .app-col div.text {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem !important;
}
.table-row .app-col:not(:first-child) {
  padding-top: 0;
  padding-bottom: 0;
}
.table-row ion-item {
  --padding-start: 0;
  --background: transparent;
  --inner-padding-end: 0;
}
.table-row.active ion-item {
  --background: transparent;
}
.table-row .app-col:first-child ion-item {
  --padding-start: #{app-padding(lg)};
}
.table-row .file-icon {
  margin-right: 1rem;
  font-size: 30px;
}
.mobile .file-icon {
  margin-right: 1rem;
  font-size: 40px;
}
.custom .file-icon {
  margin-right: 1rem;
  font-size: 40px;
}
.table-row .spinner {
  margin-right: 1rem;
}

.grid-item {
  height: 100%;
  background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.active {
    background-color: var(--ion-color-custom-element-lighter);
  }
  .item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    .file-icon {
      font-size: 70px;
      color: var(--ion-color-medium);
    }
  }

  .item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    overflow: hidden;
  }
  .item-label {
    position: relative;
    height: 2rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2rem;
    padding-inline: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }
}

ion-item.custom {
  --background: transparent;
  width: 100%;
}
ion-item.mobile.active {
  --background: var(--ion-color-custom-element-lighter);
}
ion-item.mobile .file-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 1rem;
}

@include respond-to-min-width(2xl) {
  .table-row .app-col div.text {
    font-size: 1rem !important;
  }
  .grid-item {
    .item-label {
      font-size: 1rem;
    }
  }
}
</style>
