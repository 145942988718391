import type { DocumentExtensionEnum } from '@/enums';
import { FeedTypeEnum } from '@/enums';
import { useErrors } from '@/helpers';
import axios from '@/services/axios';
import { useAppStore } from '@/store';
import type {
  ResponseErrorModel,
  ResponseFileModel,
  ResponseSuccessModel,
  ErrorMessageModel,
} from '@/types';

export class FileApiService {
  async media(url: string): Promise<Blob | ResponseErrorModel> {
    const { handleError } = useErrors();
    const appStore = useAppStore();

    if (!url.startsWith('http')) {
      url = `${appStore.url}${url}`;
    }

    const response = await fetch(url);

    if (response.status !== 200) {
      handleError(false, response);
      return {
        statusCode: response.status,
        traceId: '',
        errorType: 'bad_file',
        errorMessages: [] as ErrorMessageModel[],
      };
    }

    return response.blob();
  }

  async upload(file: File): Promise<ResponseFileModel | ResponseErrorModel> {
    const formData = new FormData();
    formData.append('attach', file);
    return axios.post('/storage/UploadMulti', formData);
  }

  async delete(
    fileId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/delete/${fileId}`);
  }
  async downloadPost(
    type: FeedTypeEnum,
    documentExtension: DocumentExtensionEnum,
    postId: number
  ): Promise<Blob | ResponseErrorModel> {
    const getType = () => {
      switch (type) {
        case FeedTypeEnum.Text:
          return 'posts';

        case FeedTypeEnum.Idea:
          return 'ideas';

        case FeedTypeEnum.Poll:
          return 'polls';

        case FeedTypeEnum.Announcement:
          return 'announcements';

        case FeedTypeEnum.Event:
          return 'events';

        default:
          return 'posts';
      }
    };
    return axios.get(`/storage/${getType()}/${documentExtension}/${postId}`, {
      responseType: 'blob',
    });
  }
}
